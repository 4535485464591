import React from 'react';
import NavMenu from "../Components/NavMenu.js"
import { Link } from 'gatsby';
import Title from '../Components/Title'

const SideBar = props => {
  return (
    <div className="titleMenu">
      <Link to="/">
        <Title
          slug = {props.slug}
          topic = {props.topic}
          highlightColor = {props.highlightColor}
          lowlightColor = {props.lowlightColor}
        />
      </Link>
      <NavMenu
        clearArticleObjects = {props.clearArticleObjects}
        highlightColor = {props.highlightColor}
        lowlightColor = {props.lowlightColor}
      />
      <div className="menuContainer">
        <a href = "https://superculture.network/">
          <div className = "podcastLink">
            SuperCulture
          </div>
        </a>
        <a href = "https://www.bulletpointspodcast.com/">
          <div className = "podcastLink" id="bppodcast">
            BP Podcast
          </div>
        </a>
        <a href ="https://www.patreon.com/bulletpoints">
          <div className = "patreonLink">
            Patreon
          </div>
        </a>
      </div>
    </div>
  );
}

export default SideBar;
