import React from 'react';
import { Link } from 'gatsby';

const NavMenu = props => {

  let menuItemStyles={
    color: `#${props.highlightColor}`
  }

  return (
    <div className='menuContainer'>
      <Link to={"/about"}><div className="menuItem" style={menuItemStyles}>About</div></Link>
      <Link to={"/okayhero"}><div className="menuItem" style={menuItemStyles}>Okay, Hero</div></Link>
      <Link to={"/archive"}><div className="menuItem" style={menuItemStyles} onClick={props.clearArticleObjects}>Back Issues</div></Link>
      <Link to={"/rss.xml"}><div className="menuItem" style={menuItemStyles}>RSS</div></Link>
    </div>
  );
}

export default NavMenu;
